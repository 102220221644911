import React from 'react';
import { graphql } from 'gatsby';
import RenderTemplate from '../../lib/renders/renderTemplate';
import {device, deviceNoAnimation} from '../../templates/devices/devices.module.scss';

const Product = ({data, pageContext, location}) => {
  const {id, template, title, displayTitle } = data?.datoCmsPosttypeProduct || {};
  return (
    <div>
      {
        (template && template.length > 0) &&
        <RenderTemplate className={`${device} ${template[0].__typename !== "DatoCmsTemplateDeviceTier1" ? deviceNoAnimation : ''}`} alldevices={data?.allDatoCmsPosttypeProduct?.devices} title={displayTitle || title} id={id} location={location} intl={pageContext.locale} data={template[0]} />
      }
    </div>
  )
}

export default Product;

export const productQuery = graphql`
  query($id: String, $slug: String, $category__archivePage__slug: String, $locale : String!) {
    datoCmsPosttypeProduct(originalId : {eq: $id}, locale : {eq: $locale} ) {
      displayTitle
      id
      model{
        name
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      template {
        __typename
        ...ApplicationFragment
        ...DeviceTier1TemplateFragment
        ...DeviceTier2TemplateFragment
        ...DeviceTier3TemplateFragment
        ...DeviceTier4TemplateFragment
      }
      title
    }

    allDatoCmsPosttypeProduct(
      filter: {locale : {eq: $locale}, slug:  {ne : $slug}, category: {originalId: {eq: "59448614"}, archivePage: {slug: {eq: $category__archivePage__slug}}}}
      limit: 10
      ) {
      devices: nodes{
        seo{
          description
          image{
            gatsbyImageData
            url
            height
            width
          }
        }
        slug
        title
      }
    }
  }
`



