import React, {lazy, Suspense} from "react";
import Render from "./render";

const deviceTier1 = lazy(() => import('../../templates/devices/deviceTier1/deviceTier1'));
const deviceTier2 = lazy(() => import('../../templates/devices/deviceTier2/deviceTier2'));
const deviceTier3 = lazy(() => import('../../templates/devices/deviceTier3/deviceTier3'));
const deviceTier4 = lazy(() => import('../../templates/devices/deviceTier4/deviceTier4'));
const Application = lazy(() => import('../../templates/applications/applications'));

/**
 * 
 * 
 * @category Renders
 * @summary Renders the correct Template component to the page
 * @param {object} data - cms template data
 * @return {Component} Returns the correct Template
 * 
 */

const renderTemplate = ({data, ...other}) => {
  const map = {
      'DatoCmsTemplateDeviceTier1' : deviceTier1,
      'DatoCmsTemplateDeviceTier2' : deviceTier2,
      'DatoCmsTemplateDeviceTier3' : deviceTier3,
      'DatoCmsTemplateDeviceTier4' : deviceTier4,
      'DatoCmsTemplateApplication' : Application
    };

    return(
      <Suspense fallback="">
        <Render data={data} map={map} {...other} />
      </Suspense>
    );
};

export default renderTemplate;
